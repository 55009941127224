import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      (_ctx.reasonType === 'reject')
        ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rejectReasons, (reason) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: reason.enumId,
                  onClick: ($event: any) => (_ctx.updateIssue(reason.enumId)),
                  button: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(reason.description ? _ctx.translate(reason.description) : reason.enumDescription ? _ctx.translate(reason.enumDescription) : reason.enumId), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cancelReasons, (reason) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: reason.enumId,
                  onClick: ($event: any) => (_ctx.updateIssue(reason.enumId)),
                  button: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(reason.description ? _ctx.translate(reason.description) : reason.enumDescription ? _ctx.translate(reason.enumDescription) : reason.enumId), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}